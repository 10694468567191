'use client';

import { motion } from 'framer-motion';
import Image from 'next/image';
import { formatPhoneNumberSplit } from '@repo/common/utils/formatters';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import OtmowMosaic from './otmow_mosaic.png';
import HomeButton from './HomeButton';

export default function AboutSection() {
  const { t } = useTranslation();
  return (
    <section id="about-us" className="overflow-x-clip max-w-screen-xl mx-auto items-center flex flex-col-reverse md:flex-row px-8 py-12 gap-4">
      <motion.div
        className="grow basis-1/2"
        initial={{ opacity: 0, translateX: -100 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        transition={{ duration: 1 }}
        viewport={{ amount: 0.25, once: true }}
      >
        <Image className="aspect-[19/20] height-[350px] rounded-xl object-cover" src={OtmowMosaic.src} width={OtmowMosaic.width} height={OtmowMosaic.height} alt="Hero image" />
      </motion.div>
      <motion.div
        className="grow basis-1/2 rounded-r-xl lg:px-8 py-4"
        initial={{ opacity: 0, translateX: 100 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ amount: 0.25, once: true }}
      >
        <h2 className="text-4xl mb-4 font-bold">
          {t('landing.about.otmow')}
        </h2>
        <div className="text-lg font-normal space-y-3">
          <p>
            {t('landing.about.p1')}
          </p>
          <p>
            {t('landing.about.p2')}
          </p>
          <p className="!mb-6">
            {t('landing.about.p3')}
          </p>
          <div className="p-4 rounded-xl text-blue-950 bg-blue-100/50 border border-blue-600/50">
            <h3 className="font-semibold mb-2">{t('landing.about.team')}</h3>
            <div className="flex flex-col space-y-2">
              <Link className="inline-block" href="mailto:support@otmow.com">
                <HomeButton variant="outline" size="lg" className="flex gap-1.5 items-center border-blue-600 rounded-full">
                  <span className="material-symbols-outlined text-blue-500">
                    mail
                  </span>
                  support@otmow.com
                </HomeButton>
              </Link>
              <Link className="inline-block" target="_blank" rel="noopener noreferrer" href={`https://wa.me/${process.env.NEXT_PUBLIC_WHATSAPP_CONTACT}`}>
                <HomeButton variant="outline" size="lg" className="flex gap-1.5 items-center border-green-600 rounded-full">
                  <span className="material-symbols-outlined text-green-500">
                    contact_support
                  </span>
                  {formatPhoneNumberSplit(process.env.NEXT_PUBLIC_WHATSAPP_CONTACT!).phoneNumber}
                </HomeButton>
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
}
