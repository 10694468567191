import { RadioGroupProps as AntRadioGroupProps, CheckboxOptionType, Radio } from 'antd';
import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';
import radioInputStyles from './radioInput.module.scss';

interface RadioGroupWithOptionsProps extends AntRadioGroupProps {
  formRegister: UseFormRegisterReturn,
  formControl: any;
  options: CheckboxOptionType[];
  className?: string;
  label?: string,
  status?: string,
}

const RadioGroupWithOptions = ({
  formRegister, formControl, options, className = '', label = undefined, status, ...props
}: RadioGroupWithOptionsProps) => (
  <div className={classNames(radioInputStyles.radioGroupContainer, 'otmow-radio-group-with-options', className)}>
    {label && (<span className={radioInputStyles.label}>{label}</span>)}
    <Controller
      name={formRegister.name}
      control={formControl as Control}
      render={({ field }) => (
        <Radio.Group {...field} className={classNames(radioInputStyles.radioGroup, status === 'error' && 'border border-red-500')} {...props}>
          {options.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key, @typescript-eslint/no-unsafe-assignment
            <Radio value={option.value} key={index} className="bg-slate-100">
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      )}
    />
  </div>
);

interface RadioGroupProps extends AntRadioGroupProps {
  formRegister: UseFormRegisterReturn,
  formControl: any;
  className?: string;
  radioClassname?: string;
  label?: string,
  children?: React.ReactNode;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  formRegister, formControl, className = '', radioClassname = '', label = null, children = undefined, ...props
}) => (
  <div className={classNames('otmow-radio-group', className)}>
    {label && (<span className={classNames(radioInputStyles.label, radioClassname)}>{label}</span>)}
    <Controller
      name={formRegister.name}
      control={formControl as Control}
      render={({ field }) => (
        <Radio.Group
          className={radioClassname}
          {...field}
          {...props}
        >
          {children}
        </Radio.Group>
      )}
    />
  </div>
);

export { Radio, RadioGroup, RadioGroupWithOptions };
