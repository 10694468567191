'use client';

import { Input as AntInput, InputNumber as AntInputNumber, InputNumberProps, InputProps, ConfigProvider } from 'antd';
import { TextAreaProps } from 'antd/lib/input/';
import classNames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-number-input';
import colors from 'tailwindcss/colors';
import textInputStyles from './textInput.module.scss';

interface TextInputProps {
  label?: string | React.ReactNode,
  formRegister: UseFormRegisterReturn,
  formControl: any,
  className?: string,
  inputClassName?: string,
  formatter?: (value: string) => string
  status?: InputProps['status'],
  readOnly?: boolean,
  parser?: (value: string) => string | number,
}

function handleValue(value: unknown) {
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'number') {
    return String(value);
  }
  return '';
}

const commonComponent: React.FC<TextInputProps> = ({
  label, formRegister, formControl, className = '', inputClassName = '', formatter, parser, ...props
}, type: string) => (
  <label className={classNames(textInputStyles.textInput, className, 'otmow-text-input')}>
    { label ? <span className={classNames(textInputStyles.label, 'mb-1 text-sm', className)}>{label}</span> : null }

    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            colorBgContainer: props.readOnly ? colors.neutral[100] : '',
          },
        },
      }}
    >
      <Controller
        name={formRegister.name}
        control={formControl as Control}
        render={({ field: _field }) => {
          const [savedSelectionStart, setSavedSelectionStart] = useState<number|null>(null);
          const inputRef = useRef<HTMLInputElement | null>(null);

          const originalOnChange = _field.onChange;
          let field = _field;
          if (['text', 'password', 'search', 'textarea'].includes(type)) {
            field = {
              ..._field,
              onChange: (e: { target: { value: string, selectionStart: number } }) => {
                let newSelectionStart = e.target.selectionStart;
                if (formatter) newSelectionStart = formatter(e.target.value?.substring(0, newSelectionStart)).length;
                if (parser) {
                  e.target.value = parser(e.target.value) as string ?? '';
                }
                setSavedSelectionStart(newSelectionStart);
                originalOnChange(e);
              },
              value: formatter ? formatter(_field.value ? String(_field.value) : '') : handleValue(_field.value),
            };
          }

          const originalFieldRef = field.ref;
          field.ref = (el: HTMLInputElement) => {
            // Pass the DOM element to field.ref
            originalFieldRef(el);

            // Also attach it to our local ref
            inputRef.current = el;
          };

          useEffect(() => {
            if (inputRef.current?.setSelectionRange && savedSelectionStart !== null) {
              inputRef.current.setSelectionRange(savedSelectionStart, savedSelectionStart);
            }
          }, [savedSelectionStart]);

          if (type === 'text') return (<AntInput className={inputClassName} {...props} {...field} />);
          if (type === 'password') return (<AntInput.Password className={inputClassName} {...props} {...field} />);
          if (type === 'search') return (<AntInput.Search className={inputClassName} {...props} {...field} />);
          if (type === 'textarea') return (<AntInput.TextArea className={inputClassName} {...props} {...field} />);
          if (type === 'phone') return (<ReactPhoneInput className={inputClassName} {...props} {...field} />);
          if (type === 'number') return (<AntInputNumber formatter={formatter as (value: string|undefined) => string} parser={parser as (value: string|undefined) => string} className={classNames('w-full', inputClassName)} {...props} {...field} />);
          throw Error('Invalid type');
        }}
      />
    </ConfigProvider>

  </label>
);

const TextInput: React.FC<TextInputProps & InputProps> = (options) => commonComponent(options, 'text');
const PasswordInput: React.FC<TextInputProps & InputProps> = (options) => commonComponent(options, 'password');
const SearchInput: React.FC<TextInputProps> = (options) => commonComponent(options, 'search');
const TextAreaInput: React.FC<TextInputProps & TextAreaProps> = (options) => commonComponent(options, 'textarea');
const PhoneInput: React.FC<TextInputProps> = (options) => commonComponent(options, 'phone');
const NumberInput: React.FC<TextInputProps & InputNumberProps> = (options) => commonComponent(options, 'number');

export { NumberInput, PasswordInput, PhoneInput, SearchInput, TextAreaInput, TextInput };
